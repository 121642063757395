import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgImageSliderComponent } from 'ng-image-slider';
import { TextAnimation } from 'ngx-teximate';
import { fadeIn, fadeInDown, fadeInLeft } from 'ng-animate';
import { Subscription, interval } from 'rxjs';

@Component({
  selector: 'app-index5',
  templateUrl: './index5.component.html',
  styleUrls: ['./index5.component.scss']
})
/**
 * Index-5 component
 */
export class Index5Component implements OnInit, OnDestroy {

  private subscription: Subscription;

  imageObject: Array<object> = [
    {
    image: 'assets/images/home/1.jpg',
    thumbImage: 'assets/images/home/1.jpg',
    alt: 'alt of image',
    title: ''
    }, 
    {
      image: 'assets/images/home/2.jpg',
      thumbImage: 'assets/images/home/2.jpg',
      alt: 'alt of image',
      title: ''
    },
    {
      image: 'assets/images/home/3.jpg',
      thumbImage: 'assets/images/home/3.jpg',
      alt: 'alt of image',
      title: ''
    },



];

text:String[] = [
  'Help Us Serve Us Better',
  'Help Serve You Better',
  'Help Us Serve You Better'
];
current:number = 0;

  enterAnimation: TextAnimation = {
    animation: fadeInLeft,
    delay: 50,
    type: 'letter',

  };
  currentSection = 'home';

  constructor() { }

  ngOnInit(): void {
    this.subscription = interval(3000)
    .subscribe(x => { 
      this.current=(this.current+1)%3;
     });
  }
  
  ngOnDestroy() {
    this.subscription.unsubscribe();
 }
  /**
   * Window scroll method
   */
  windowScroll() {
    const navbar = document.getElementById('navbar');
    if (document.body.scrollTop >= 50 || document.documentElement.scrollTop > 50) {
      navbar.classList.add('nav-sticky');
    } else {
      navbar.classList.remove('nav-sticky');
    }
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }
}
