
<section class="section bg-light" id="vision" >
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <h4 class="mb-3">Our Vision</h4>
         </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> Why MoPay?
        </h5>

          
        <div class="card " >
          <div class="card-body p-4">
            <div  class="row">
              <img src="assets/images/vision.jpg" class="col-lg-6" alt="" width="100%" height="100%">
              <p class="mb-4 col-lg-6 ml-lg-auto col-sm-8 description" > 
                MoPay Express is a global money transfer and remittance service provider offering secured payment gateway solutions for cross border transactions. To date, sending money overseas is strategically kept costly at the expense of the sender. We believe there are smarter and innovative ways to create competitive offerings to help our customers and their communities benefit more from the money they send to support their loved ones and local communities. We provide the interface and technological capacity to facilitate intra-regional and global financial flows, support trade, and commerce across Africa and beyond.
              </p>
            </div>
          </div>
        </div>
       
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> MoPay Express and Africa
        </h5>

        <div class="card">
          <div class="card-body p-4">
            <p class="mb-4 description">
              There are many money transfer providers claiming they are providing African diasporas with the best lower cost solutions possible when it comes to sending money to the continent. Yet, the transaction cost for sending money to anywhere else than Africa is cheaper. 
            </p>
            <p class="mb-4 description">
              MoPay Express provides a payment service for users of mobile devices or web to submit a request for an actuarial money transfer.  Our service validates and processes the received data and, if validated correctly, allows the system to proceed with cash transaction domestically or internationally. We are seeking to provide you with simplification and harmonisation of your transfer processes by lowering the cost of cross-border remittances to Africa through the removal of excessive fees. 
            </p>
            <p class="mb-4 description">
              The global momentum in the mobile payments space is expected to return to Africa from China, especially if financial institutions and FinTech innovative companies take the lead. 
About 400 million users in sub-Sahara Africa are reported to use mobile payment banking systems to exchange $300 billion worth of mobile money transactions. The mobile payment market is projected to reach $650 million to 750 million consumers by 2025. MoPay Express is a solution by one African immigrant for the continent. We believe our solution for our continent will force other institutions to start doing right by Africa and other immigrant families from other regions. 

            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> Mission Statement for Africa?
        </h5>

        <div class="card">
          <div class="card-body p-4">
            <p class="mb-4 description">
              MoPay Express’s mission is to enable Africa to become the financial powerhouse that it already is. This is achievable if MoPay works towards: 
            </p>
            <ul class="description">
              <li>Forging a strategic and equitable commercial and financial flow relationship with Asia;</li>
              <li>Facilitating remittances to Africa and international at a competitive cost;</li>
              <li>Making accessible financial institutions and innovative payments solutions across the informal economies on the continent;</li>
              <li>Facilitating inter-regional payment flows;</li>
              <li>Facilitating transaction flows base on cross-border commercial payments;</li>
              <li>Enabling economic development, digital transformation and financial inclusion in Africa through mobile payment systems;</li>
              <li>Supporting Africa’s continental free trade agreement to realise its full potential;</li>
              <li>Providing FinTech support to African banks, telecommunication companies and existing financial institutions for the development of Africa’s financial infrastructure;</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> Why is MoPay Express expanding into non-African territories such as Philippines, India, Korea and others? 
        </h5>

        <div class="card">
          <div class="card-body p-4 description">
            <p class="mb-4 ">
              We believe we all want the same thing and if something isn’t right, there’s no amount of marketing one can pour into to make it right. If our solution has been beneficial to the African continent, we believe immigrants from other countries can equally benefit from our broader solutions. Investors can find out more about MoPay Express’s ecosystem including our upcoming projects and access how we believe our solutions can be tailored to all global communities and service the entire 250 million plus immigrants working away from home but with genuine desires to help improve their local economies and the welfare of their people. We make your contribution count. 
            </p>
            <p>If our solution is beneficial to the African continent, we are convinced immigrants from other countries can benefit from our broader cross-border payment solutions. Institutional investors can request to find out more about MoPay Express’s ecosystem and our upcoming projects. You will be enlightened about our upcoming projects in our pipeline for your consideration and investment.
              
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> MoPay Licensing Requirements & Strategic Partnerships
        </h5>

        <div class="card">
          <div class="card-body p-4 description">
            <p class="mb-4">
              As a FinTech business, MoPay is operating as a regulated Electronic Money Institution in Australia, South Korea, across Africa and other major economic power house. As a result, meeting the licensing requirements within the regions we wish to operate is key to our success. MoPay will apply for the right licenses and be regulated in the following countries:
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

  </div>
  <!-- end container -->
</section>

