<!-- Footer start -->
<footer class="footer">
  <div class="container">
    <div class="row">
      <span id="siteseal"><script async type="text/javascript" src="https://seal.godaddy.com/getSeal?sealID=nKPt5pjn6YPNm3vWnKKhYzTE7rOPLqkXI3SJNE1wY5HVl4GKN7dzJwY67Qlk"></script></span>

   
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->

</footer>
<!-- Footer end -->

<!-- Footer alt start -->
<section class="bg-primary py-3">
  <div class="container">
    <div class="row">
      <div class="col-lg-12">
        <div class="float-sm-left">
          <a href="javascript: void(0);">
            <img src="assets/images/logo.png" alt="" height="20">
          </a>
        </div>
        <div class="float-sm-right mt-4 mt-sm-0">
          <p class="copyright-desc text-white mb-0">{{year}} © MoPay proprietary Limited, Australia</p>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Footer alt start -->
