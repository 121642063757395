<!--Navbar Start-->
<nav class="navbar navbar-expand-lg fixed-top navbar-custom sticky sticky-dark navbar-light"
  (window:scroll)="windowScroll()" id="navbar">
  
  <div class="container">
    <!-- LOGO -->
    <a class="logo text-uppercase" href="/">
<!--       <img src="assets/images/logo.png" class="logo-light" alt="" height="20">
      <img src="assets/images/logo.png" alt="" class="logo-dark" height="20" /> -->
      <label class="logo-dark"  style="color: rgb(50, 27, 153);height: 20px;" ><b><span style="color: red;">M</span>oPayExpress</b></label>
      <label class="logo-light"  style="color: rgb(121, 161, 173);height: 10px;" ><b><span style="color: red;">M</span>oPayExpress</b></label>
    </a>

    <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse"
      aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation" (click)="toggleMenu()">
      <i class="mdi mdi-menu"></i>
    </button>
    <div class="collapse navbar-collapse" id="navbarCollapse">
      <ul class="navbar-nav ml-auto navbar-center" id="mySidenav">
        <li class="nav-item" [ngClass]="{'active':currentSection === 'home'}">
          <a [ngxScrollTo]="'#home'" href="javascript: void(0);" class="nav-link">Home</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'services'}">
          <a [ngxScrollTo]="'#services'" href="javascript: void(0);" class="nav-link">Services</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'features'}">
          <a [ngxScrollTo]="'#features'" href="javascript: void(0);" class="nav-link">Features</a>
        </li>

        <li class="nav-item" [ngClass]="{'active':currentSection === 'vision'}">
          <a [ngxScrollTo]="'#vision'" href="javascript: void(0);" class="nav-link">Our Vision</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'pricing'}">
          <a [ngxScrollTo]="'#pricing'" href="javascript: void(0);" class="nav-link">Fees</a>
        </li>
        <li class="nav-item" [ngClass]="{'active':currentSection === 'clients'}">
          <a [ngxScrollTo]="'#clients'" href="javascript: void(0);" class="nav-link">About Us</a>
        </li>

      </ul>
      <button class="btn btn-success btn-rounded navbar-btn">Client Sign In</button>

    </div>
  </div>
</nav>
<!-- Navbar End -->
<div appScrollspy [spiedTags]="['SECTION']" (sectionChange)="onSectionChange($event)">

  <!-- Hero section Start -->
  <section class="hero-section-5" id="home" style="background: url(assets/images/bg.jpg);background-repeat:repeat; background-size: cover">
  <!-- <section class="hero-section-5" id="home" > -->
    <!-- <div class="bg-overlay"></div> -->
    <div class="container">
        <div class="row justify-content-center">
          <div class="col-lg-6">
            <div class="hero-wrapper text-center mb-4" >
   
                <p class="font-16 text-uppercase text-white-50">DISCOVER MOPAY EXPRESS TODAY</p>
              <!-- <h1 class="hero-title text-white mb-4">Help Us Serve Us Better / Help Serve You Better / Help Us Serve You Better</h1> -->

              <div class="description">
                <teximate *ngIf="this.current==0" [text]="this.text[0]"  [enter]="enterAnimation"  ></teximate>
                <teximate *ngIf="this.current==1"  [text]="this.text[1]" [enter]="enterAnimation"  ></teximate>
                <teximate *ngIf="this.current==2"  [text]="this.text[2]" [enter]="enterAnimation"  ></teximate>
              </div>
              <p class="text-white-50" style="text-align: justify;">Our support is helpful to the growth and development of our continent. Over 5.6 million of us are now sending over $85 billion per annum into our communities. The time is now and our transfer process is convenient, fast and very competitive. </p>

              <div class="mt-4" [hidden]="true">
                <a href="javascript: void(0);" class="btn btn-primary mt-2 mr-2">Get Started</a>
                <a href="javascript: void(0);" class="btn btn-success mt-2 mr-2">Learn more</a>
              </div>
            </div>

          </div>
          <div class="col-lg-6">
            
              <ng-image-slider #nav style="padding: 0,0,0,0;margin: 0,0,0,0;"
              [images]="imageObject"
              [infinite]="true"
              [autoSlide]="1"
              [manageImageRatio]="true"
              [imageSize]="{width: 266, height: 200, space: 3}"

              slideImage="1"></ng-image-slider>
          </div>
        </div>


      <!-- end row -->
    </div>
    <!-- end container -->
  </section>
  <!-- Hero section End -->
  <app-services></app-services>
  <app-features></app-features>
  <app-vision></app-vision>
  <app-plans></app-plans>
  <app-clients></app-clients>
  <app-footer></app-footer>
</div>
