const testimonialData = [
    {
        // tslint:disable-next-line: max-line-length
        message: 'We believe we all want the same thing and if something isn’t right, there’s no amount of marketing one can pour into to make it right. If our solution has been beneficial to the African continent, we believe immigrants from other countries can equally benefit from our broader solutions. Investors can find out more about MoPay Express’s ecosystem including our upcoming projects and access how we believe our solutions can be tailored to all global communities and service the entire 250 million plus immigrants working away from home but with genuine desires to help improve their local economies and the welfare of their people. We make your contribution count.If our solution is beneficial to the African continent, we are convinced immigrants from other countries can benefit from our broader cross-border payment solutions. Institutional investors can request to find out more about MoPay Express’s ecosystem and our upcoming projects. You will be enlightened about our upcoming projects in our pipeline for your consideration and investment.',
        username: 'Why is MoPay Express expanding into non-African territories such as Philippines, India, Korea and others? '
    },
    {
        // tslint:disable-next-line: max-line-length
        message: 'MoPay Express is a global money transfer and remittance service provider offering secured payment gateway solutions for cross border transactions. To date, sending money overseas is strategically kept costly at the expense of the sender. We believe there are smarter and innovative ways to create competitive offerings to help our customers and their communities benefit more from the money they send to support their loved ones and local communities. We provide the interface and technological capacity to facilitate intra-regional and global financial flows, support trade, and commerce across Africa and beyond.',
        username: 'Why MoPay Express?'
    },
    {
        // tslint:disable-next-line: max-line-length
        message: '" Ben reached out to Babar (MoPay Express CTO) and Baowen (MoPay Express Technical Director) and discussed his vision and solutions for Africa and the global remittance market. Babar and Baowen couldn’t be more excited about what they were hearing. They proposed they already have the base technology to help facilitate the vision but required a little investment to get it ready for the market. The parties came to a resolution, formed a new company together with William. We now introduce MoPay Express to Africa and we’re serving all 54 countries in Africa and the world.  "',
        username: 'C'
    },
];

export { testimonialData };
