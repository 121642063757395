<!-- Testimonial start -->
<section class="section bg-light" id="clients">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">
          <img src="assets/images/aboutus.png" class="logo-light" alt="" height="120">
          <!-- <h5 class="text-primary text-uppercase small-title">About Us</h5> -->
         </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> About MoPay Express
        </h5>

        <div class="card">
          <div class="card-body p-4 description">
            <p class="mb-4">
              MoPay is a global FinTech and remittance service provider offering secured payment gateway solutions for cross border transactions. We provide the interface and technological capacity to facilitate intra-regional and global financial flows, support trade and commerce across Africa and beyond. The United Nations has been advocating for banks and money transfer institutions to offer their services at a more competitive rates to sending money to developing countries. The market however is still strategically kept expensive than usual at the expense of people from developing countries. MoPay Express is here to be part of the solution and help foster competition and protect our customers from the excessive fees associated with sending money overseas. 
            </p>
            <p>Send your money through us and let us continue serving you better than our competitors. 
            </p>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> How This All Started:
        </h5>
        <owl-carousel-o [options]="testimonialOptions" class=" testi-carousel">
          <ng-template carouselSlide *ngFor="let item of testimonialData">
            <div class="item">
              <div class="card">
                <div class="card-body p-4 description" style="height:550px;">

                 
                  <div class="media pt-3" >
                    <div class="avatar-md mr-3 ">
                      <span class="avatar-title rounded-circle bg-soft-primary text-primary font-16">
                        {{item.username.charAt(0)}}
                      </span>
                    </div>
                    <div class="text-muted ml-2 align-self-end d-none d-lg-block">
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                      <i class="mdi mdi-star text-warning"></i>
                    </div>

                  </div>
                  <p class="mb-4">{{item.message}}</p>
                </div>
              </div>
            </div>
          </ng-template>
        </owl-carousel-o>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-12">
        <h5 class="mb-4"><i class="mdi mdi-format-quote-open text-primary h1 mr-1"></i> Contact Us:
        </h5>

        <div class="card">
          <div class="card-body p-4 description">
            <p class="mb-4">
              <a href="mailTo:ben.moses@mopayexpress.com" > Email:   ben.moses@mopayexpress.com</a>
             </p>
          </div>
        </div>
      </div>
    </div>
       <!-- end row -->

  </div>
  <!-- end container -->
</section>
<!-- Testimonial end -->


<!-- Counter end -->
