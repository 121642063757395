  <!-- Pricing start -->
  <section class="section bg-light" id="pricing">
    <div class="container">
      <div class="row justify-content-center">
        <div class="">
          <div class="text-center mb-5">
            <!-- <h5 class="text-primary text-uppercase small-title">Pricing</h5> -->
            <h4 class="mb-3">Lower Fees</h4>
           </div>
          
        </div>
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body p-4 description">

              <p  class="description">We’re on a mission to become your default choice of mobile payment options to sending money to Africa. We’re serving you better with transparency, lower fees, competitive exchange rates and your support helps us to invest more in your region. How do you register to send money? </p>
          
            </div>
          </div>
        </div>
      </div>
      <!-- end row -->

    </div>
    <!-- end container -->
  </section>
  <!-- Pricing end -->


