<!-- Features start -->
<section class="section" id="features">
  <div class="container">
    <div class="row justify-content-center">
      <div class="col-lg-8">
        <div class="text-center mb-5">

          <h4 class="mb-3">Key Features Of The Product</h4>
          <p>It will be as simple as occidental in fact, it will be Occidental.</p>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Mobile Payment system</h5>
          <p class="mb-4 description">MoPay Express is a fast , reliable and user friendly mobile payment system. </p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> App for IOS
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> App for Andriod
              </p>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Backend on Cloud
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Multiple Agencies
              </p>
            </div>
          </div>

          <div class="mt-4" [hidden]="true">
            <a href="javascript: void(0);" class="btn btn-primary">Learn more <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/MoPay-HomePage-NoShadow.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->

    <div class="row mt-5 pt-5">
      <div class="col-lg-5 col-sm-8">
        <div class="card border border-light shadow-none">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/MoPay-Profile-NoShadow.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-5 ml-lg-auto">
        <div class="mt-4 mt-lg-0">
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="pie-chart" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Traceable Transactions </h5>
          <p class="mb-4 description">You can trace your payment and view you transaction history.</p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Trace your payment
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> View transactions
              </p>
            </div>
          </div>

          <div class="mt-4" [hidden]="true">
            <a href="javascript: void(0);" class="btn btn-primary">Learn more <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

    </div>
    <!-- end row -->
    <div class="row">
      <div class="col-lg-5">
        <div>
          <div class="avatar-md mb-4">
            <span class="avatar-title rounded-circle bg-soft-primary">
              <i-feather name="bar-chart-2" class="icon-dual-primary"></i-feather>
            </span>
          </div>
          <h5>Mobile Payment system</h5>
          <p class="mb-4 description">MoPay Express is a fast , reliable and user friendly mobile payment system. </p>

          <div class="row">
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> Fully secured 
              </p>
            </div>
            <div class="col-sm-6">
              <p>
                <i-feather name="check" class="icon-dual-success mr-2"></i-feather> 2 factor auth
              </p>
            </div>
          </div>

          <div class="mt-4" [hidden]="true">
            <a href="javascript: void(0);" class="btn btn-primary">Learn more <i-feather name="arrow-right"
                class="icons-sm ml-1">
              </i-feather></a>
          </div>
        </div>
      </div>

      <div class="col-lg-5 ml-lg-auto col-sm-8">
        <div class="card border border-light shadow-none mt-5 mt-lg-0">
          <div class="card-header border-0 bg-transparent">
            <div>
              <i class="mdi mdi-circle text-danger mr-1"></i>
              <i class="mdi mdi-circle text-warning mr-1"></i>
              <i class="mdi mdi-circle text-success mr-1"></i>
            </div>
          </div>
          <div class="card-body bg-light">
            <div class="box-shadow">
              <img src="assets/images/features/MoPay-Settings-NoShadow.png" alt="" class="img-fluid mx-auto d-block">
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- end row -->
  </div>
  <!-- end container -->
</section>
<!-- Features end -->
