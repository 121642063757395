const testimonialData = [
    {
        // tslint:disable-next-line: max-line-length
        message: 'Ben and William met in 2018 in Ghana. At the time, Ben was working on a 15,000 affordable housing solution with the Ghana government. William presented his waste management solution for Ben’s project to him and his Australian finance partners at the Kempinski Hotel in Accra. In late 2020, William reached out to Ben with a new business proposal to develop a mobile money application for Ghana. As an immigrant and a qualified financial and investment adviser in Australia, he expanded the idea and identified the African remittance market is heavily exploited.           ',
        username: '1'
    },
    {
        // tslint:disable-next-line: max-line-length
        message: 'Sending money to anywhere else in the world is cheaper than sending it to Africa. Different groups with limited to no ties to Africa are currently providing remittance service solutions to the continent at a painful cost to the African diasporas. He explained his findings to William and vowed to create a competitive but suitable solution to Africa and its diasporas like himself.                                                                                                                                                                                              ',
        username: '2'
    },
    {
        // tslint:disable-next-line: max-line-length
        message: 'Ben reached out to Babar (MoPay Express CTO) and Baowen (MoPay Express Technical Director) and discussed his vision and solutions for Africa and the global remittance market. Babar and Baowen couldn’t be more excited about what they were hearing. They proposed they already have the base technology to help facilitate the vision but required a little investment to get it ready for the market. The parties came to a resolution, formed a new company together with William. We now introduce MoPay Express to Africa and we’re serving all 54 countries in Africa and the world.',
        username: '3'
    },
];

export { testimonialData };
