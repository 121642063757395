 <!-- Services start -->
 <section class="section bg-light" id="services">
   <div class="container">
     <div class="row justify-content-center">
       <div class="col-lg-8">
         <div class="text-center mb-5">
           <h4 class="mb-3">Global Remittance Service</h4>
           <p>How to send money from Here to Your Country at Lower Cost</p>
         </div>
       </div>
     </div>
     <div class="row">
       <div class="col-xl-4 col-sm-6" *ngFor="let item of serviceData">
         <div class="text-center p-4 mt-3">
           <div class="avatar-md mx-auto mb-4">
             <span class="avatar-title rounded-circle bg-soft-primary">
               <i-feather name="{{item.icon}}" class="icon-dual-primary"></i-feather>
             </span>
           </div>
           <h5 class="font-18">{{item.title}}</h5>
           <p class="mb-0 description" style="text-align:justify;">{{item.text}}</p>
         </div>
       </div>

     </div>
     <!-- end row -->

     <div class="row mt-4" [hidden]="true">
       <div class="col-lg-12">
         <div class="text-center">
           <a href="javascript: void(0);" class="btn btn-success">View more</a>
         </div>
       </div>
     </div>
     <!-- end row -->
   </div>
   <!-- end container -->
 </section>
 <!-- Services end -->
