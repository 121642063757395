import { Component, OnInit } from '@angular/core';
import { OwlOptions } from 'ngx-owl-carousel-o';

import { VisionData } from './vision.model';
import { testimonialData } from './data';

@Component({
  selector: 'app-vision',
  templateUrl: './vision.component.html',
  styleUrls: ['./vision.component.scss']
})

/**
 * Vision component
 */
export class VisionComponent implements OnInit {

  visionData: VisionData[];

  constructor() { }

  testimonialOptions: OwlOptions = {
    margin: 10,
    loop: true,
    responsive: {
      0: {
        items: 1
      },

    },
    nav: true,
    navText: ['<i class=\'mdi mdi-chevron-left\'></i>', '<i class=\'mdi mdi-chevron-right\'></i>'],
  };

  ngOnInit(): void {
    this._fetchData();
  }

  private _fetchData() {
    this.visionData = testimonialData;
  }
}
