const serviceData = [
    { icon: 'edit', title: '1.Zero cost registration', text: 'Sign up now at zero cost or download our free app from the App Store or Google Play. All you need is an email address, or a mobile phone number, or a Google or Facebook or Instagram account.' },
    { icon: 'edit', title: '2.Fee Transparency', text: 'Input the amount you want to send. You’ll be shown your upfront fee and be given timeline for your transfer to reach its destination.  ' },
    { icon: 'edit', title: '3.Complete recipient’s details', text: 'Add full details of the recipient, select their bank or mobile money account or cash pick up.'  },
    { icon: 'edit', title: '4.Verify your identity', text: 'To protect your transfer and also meet our regulatory obligation, we require a photo Pay ID. This could be a passport copy or a driver’s licence or a verifiable government issued photo ID.  ' },
    { icon: 'edit', title: '5.Pay for your transfer', text: 'Complete your transaction with a bank transfer, a debit or credit card, POLi, PayID' },
    { icon: 'edit', title: '6.Approval', text: 'Our systems will review, approve and send money to your recipient. Track the progress of your transfer from your MoPay account. Your recipient will be notified and we will be providing you with regular update via text and email at each stage of your transaction. ' },
];

export { serviceData };
